'use client';
import React, { FC } from 'react';

import { SpotifyResources } from 'utils/spotify/searchService';
import { findImage } from 'utils';
import { defaultAlbumThumbnail } from 'config/defaultData/ImageDefault';
import SpotifyButtons from './SpotifyButtons';
import SongLink from 'components/SongLink/SongLink';
import AddFavoriteWrapper from 'components/Data/AddFavoriteWrapper';
import SongActionMenu from './SongActionMenu';
import dayjs from 'dayjs';

export interface SongProps {
  title: string;
  artist?: string | null;
  album?: string | null;
  art_url?: string | null;
  played_at?: string;
  duration?: string | null;
  song_id: number;
  service: string;
  spotifyResources?: SpotifyResources;
  hideTime?: boolean;
  showFavorite?: boolean;
  fetchFavorite?: () => void;
  isFavorite?: boolean;
  showEllipsis?: boolean;
}

const SongItem: FC<SongProps> = (song) => {
  const artSrc =
    song.art_url ||
    findImage(song.spotifyResources?.images || [], 500)?.url ||
    defaultAlbumThumbnail;

  const startDate = dayjs(song.played_at);

  const trimData = (strToStrim: string | null) => {
    return (
      strToStrim !== null && strToStrim !== '.' && strToStrim.trim() !== ''
    );
  };

  async function deleteFavorite(cmsId: string | number) {
    try {
      await fetch(`/api/data/favorite/song/${cmsId}`, {
        method: 'DELETE'
      });
      song.fetchFavorite && song.fetchFavorite();
    } catch (err) {
      console.error(err);
    }
  }

  return (
    <li className="playlist-card">
      <div>
        <div className="playlist-image">
          <SongLink service={song.service} song_id={song.song_id}>
            <img
              src={song.art_url || defaultAlbumThumbnail}
              alt="album art"
              width="185"
              height="185"
            />
          </SongLink>
        </div>
        <h4 className="playlist-title type-strong">
          <SongLink service={song.service} song_id={song.song_id}>
            {song.title}
          </SongLink>
        </h4>
        {song.artist && (
          <div className="playlist-artist">
            <SongLink service={song.service} song_id={song.song_id}>
              {song.artist}
            </SongLink>
          </div>
        )}
        {song.album && (
          <div className="playlist-artist">
            <SongLink service={song.service} song_id={song.song_id}>
              {song.album}
            </SongLink>
          </div>
        )}
      </div>
      <div className="playlist-save-container">
        {!song.hideTime && (
          <div className="playlist-time">
            {dayjs(song.played_at).format('h:mm a')}
          </div>
        )}
        <div className="playlist-save">
          <AddFavoriteWrapper
            title={song.title}
            contentArea={
              process.env.NEXT_PUBLIC_CONTENT_AREA_SLUG ?? 'the-current'
            }
            resourceType="song"
            slug=""
            id={song.song_id?.toString() ?? ''}
            album={song.album ?? undefined}
            artist={song.artist ?? undefined}
            duration={song.duration ?? undefined}
            art_url={song.art_url ?? undefined}
          />
        </div>
      </div>
    </li>
  );
};

export default SongItem;
