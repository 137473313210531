import { useEffect, useState } from 'react';
import { Song } from 'types/SongScheduleData';
import {
  SearchManyParams,
  SearchParams,
  SpotifyResources
} from 'utils/spotify/searchService';

interface Params {
  songs: Song[];
  date: string;
  service: string;
}
const useSpotifyResources = ({
  songs,
  date,
  service
}: Params): Record<string, SpotifyResources> | undefined => {
  return;
  const [spotifyResources, setSpotifyResources] =
    useState<Record<string, SpotifyResources>>();
  const [songMetaData, setSongMetaData] = useState({
    count: 0,
    date,
    service
  });

  const shouldUpdate = ({ songs, date, service }: Params) => {
    return (
      date !== songMetaData.date ||
      service !== songMetaData.service ||
      songs.length !== songMetaData.count
    );
  };

  const getResources = async (params: SearchManyParams) => {
    const response = await fetch('/api/spotify/searchMany', {
      method: 'POST',
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application-json'
      },
      body: JSON.stringify(params)
    });
    const resources = await response.json();
    setSpotifyResources(resources);
  };

  useEffect(() => {
    const songRecord: Record<string, SearchParams> = {};
    if (shouldUpdate({ songs, date, service })) {
      setSongMetaData({
        count: songs.length,
        date,
        service
      });
      songs.forEach((song) => {
        if (song.song_id) {
          songRecord[song.song_id] = {
            type: 'track',
            track: song.title,
            artist: song.artist || ''
          };
        }
      });

      getResources({
        songs: songRecord,
        date,
        service
      });
    }
  }, [songs, date, service]);

  return spotifyResources;
};

export default useSpotifyResources;
